<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <h3>Your Workspaces</h3>
          </div>
            <!-- <h6 class="card-title mb-3 text-capitalize">Your Workspaces</h6> -->
            <!-- <div class="row flex-grow">
              <div class="col-sm-6 col-md-3 grid-margin stretch-card" v-for="n in 8" :key="n">
                <div class="card">
                  <div class="card-body d-flex justify-content-between">
                    <div class="">
                      <p class="h4 text-primary">1</p>
                      <p class="text-muted">Used Devices</p>
                    </div>
                    <div class="">
                      <FeatherIcon type="message-circle" size="3em" stroke="#5591EC" fill="#5591EC" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr> -->
            <!-- <div class="d-flex justify-content-between"> -->
              <!-- <h6 class="card-title mb-3 text-capitalize text-muted">Workspaces</h6> -->
              <!-- <h6 class="card-title mb-3 text-capitalize">Your Workspaces</h6> -->
            <!-- </div> -->
          <div class="card-header">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Workspace</th>
                    <th>Status</th>
                    <th>Plan</th>
                    <th>PIC</th>
                    <th>Pricing Plan</th>
                    <!-- <th>Billing</th> -->
                    <th>Marketing Conv</th>
                    <th>Utility Conv</th>
                    <th>Authentication Conv</th>
                    <th>Service Conv</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(workspace, i) in workspaces" :key="i" @click="manage(workspace)" style="cursor: pointer">
                    <td>{{ workspace.name }}</td>
                    <td class="text-capitalize">
                      <Status :online="workspace.status == 'active'" />
                      <span class="ml-2">{{ workspace.status }}</span>
                      <p class="text-muted" style="font-size: .75em">Synced a few second ago</p>
                    </td>
                    <td>
                      <Status online />
                      <span class="ml-2">{{ workspace.products[0].name }}</span>
                      <p class="text-muted" style="font-size: .75em">{{ moment(workspace.created_at).fromNow() }}</p>
                    </td>
                    <td>
                      <span class="ml-2">{{ workspace.pic?.full_name }}</span>
                    </td>
                    <td>
                      <span class="ml-2">{{ workspace.products[0].is_postpaid ? 'Post Paid' : 'Pre Paid' }}</span>
                    </td>
                    <td>
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.marketing_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.marketing_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <td>
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.utility_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.utility_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <td>
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.authentication_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.authentication_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <td>
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.service_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.service_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <!-- <td>
                      <div class="d-flex justify-content-between mb-2">
                        <span>10%</span>
                        <span class="text-muted" style="font-size: .75em">Apr 2, 2021 - May 2, 2021</span>
                      </div>
                      <b-progress height="2px" :value="10"></b-progress>
                    </td> -->
                    <!-- <td>
                      <p class="text-muted" style="font-size: .75em">Total Ticket</p>
                      <p>
                        <el-skeleton v-if="workspace.stats_loading" :rows="1" animated />
                        <span v-else>{{ workspace.stats.total_ticket }}/{{ workspace.products[0].limit.ticket }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="text-muted" style="font-size: .75em">Open Ticket</p>
                      <p>
                        <el-skeleton v-if="workspace.stats_loading" :rows="1" animated />
                        <span v-else>{{ workspace.stats.open_ticket }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="text-muted" style="font-size: .75em">Queue</p>
                      <p>
                        <el-skeleton v-if="workspace.stats_loading" :rows="1" animated />
                        <span v-else>{{ workspace.stats.total_queue }}/1000</span>
                      </p>
                    </td> -->
                    <td>
                      <b-dropdown text="Actions" variant="outline-primary" class="m-md-2">
                        <b-dropdown-item v-if="checkPermission.read" @click.stop="manage(workspace)">Manage Workspace</b-dropdown-item>
                        <template v-if="checkPermission.update && ['idle','active'].includes(workspace.status)">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item @click.stop="editWorkspace(workspace)">Edit</b-dropdown-item>
                        </template>
                        <template v-if="workspace.status=='waiting' && checkPermission.update">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item @click.stop="handlerApprovedBtn(workspace)">Approve</b-dropdown-item>
                        </template>
                        <template v-if="checkPermission.update">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item @click.stop="postpaidWorkspace(workspace)">{{ workspace.products[0].is_postpaid ? 'Disabled Post Paid' : 'Enable Post Paid' }}</b-dropdown-item>
                        </template>
                        <template v-if="workspace.qrcode">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item @click.stop="() => { }">Chat with Workspace</b-dropdown-item>
                          <img style="border-radius: 0% !important;" :src="workspace.qrcode" />
                        </template>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="!workspaces.length" description="No workspaces"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal" title="Create New Workspace" hide-footer>
      <b-form @submit.prevent="createWorkspace" @reset="closeModal">
        <b-form-group id="ws-name-group" label="Workspace Name:" label-for="ws-name">
          <b-form-input id="ws-name" v-model="form.name" type="text" placeholder="Enter workspace name" required />
        </b-form-group>

        <b-form-group id="ws-product-group" label="Product:" label-for="ws-product">
          <b-form-select id="ws-product" v-model="form.ws_product_id" :options="product" required />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>

    <terms-condition :show="showModalTerms" />

    <b-modal v-model="showModalEdit" title="Edit Workspace" hide-footer>
      <b-form @submit.prevent="saveEdit" @reset="closeModal">
        <b-form-group id="ws-name-group" label="Workspace Name:" label-for="ws-name">
          <b-form-input id="ws-name" v-model="form.name" type="text" placeholder="Enter workspace name" required />
        </b-form-group>

        <b-form-group label="Pricing">
          <b-form-group label="Authentication Price" label-for="auth_price">
            <b-form-input id="auth_price" v-model="pricing.authentication" label="Authentication Price:" type="number" placeholder="Authentication Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Marketing Price" label-for="marketing_price">
            <b-form-input id="marketing_price" v-model="pricing.marketing" label="Marketing Price:" type="number" placeholder="Marketing Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Service Price" label-for="service_price">
            <b-form-input id="service_price" v-model="pricing.service" label="Service Price:" type="number" placeholder="Service Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Utility Price" label-for="util_price">
            <b-form-input id="util_price" v-model="pricing.utility" label="Utility Price:" type="number" placeholder="Utility Price" step=".01" required/>
          </b-form-group>
        </b-form-group>

        <b-form-group label="Approval" v-if="checkPermission.update && (checkUser.role.name.toLowerCase() === 'admin' || (checkUser.role_id.toLowerCase() !== '2e673799-d373-4872-a3e1-05fbb3e419e4' && checkUser.id === approved_by))">
          <b-form-group id="ws-pic" label="PIC Workspace : " label-for="ws-pic">
            <b-form-select id="ws-pic" v-model="approval.pic_id" :options="picList" />
          </b-form-group>
          <b-form-group id="ws-plan" label="Package Plan : " label-for="ws-plan">
            <b-form-select id="ws-plan" v-model="approval.plan" :options="product" />
          </b-form-group>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal centered v-model="showModalApprove" :title="this.$t('general.confirmation')" hide-footer @hide="handlerHideModalApprove">
      <h5 class="text-center">
        Are you sure want to approve this workspace?
      </h5>
      <p class="mb-4 text-center">
        You can not undo this action.
      </p>
      <b-form>
        <b-form-group id="ws-pic" label="PIC Workspace : " label-for="ws-pic">
          <b-form-select id="ws-pic" v-model="approvalForm.pic_id" :options="picList" required />
        </b-form-group>
        <b-form-group
          id="document-add-file_path"
          label="Attachment : "
          label-for="document-add-file_path">
          <b-form-file
            ref="addDocumentFile"
            v-model="approvalForm.file_path"
            :accept="accepted_ext['document']"
            placeholder="Choose a file or drop it here."
            drop-placeholder="Drop file here."
          ></b-form-file>
        </b-form-group>
      </b-form>
      <div class="mt-5 mb-2 text-center">
        <b-button size="sm" type="submit" :loading="loading.approveSubmit" @click="approveWorkspace" variant="primary" class="mr-2">{{ $t('general.yes') }}</b-button>
        <b-button size="sm" type="reset" @click="handlerHideModalApprove" variant="secondary">{{ $t('general.no') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { flatMap, isEmpty, uniq } from 'lodash';
import moment from 'moment';
import workspacesAPI from '@/api/workspaces';
import wsProductAPI from '@/api/wsProducts';
import analyticApi from '@/api/analytics';
import mime from 'mime';
import popupErrorMessages from '../../library/popup-error-messages';
import usersAPI from '../../api/userBackoffice';
import TermsCondWappinNew from './auth/TermsCondWappinNew.vue';
import documentAPI from '../../api/companyDocuments';
import base64File from '../../library/base64File';

export default {
  beforeCreate() {
    this.moment = moment;
  },
  name: 'Workspaces',
  metaInfo: {
    title: 'Workspaces',
  },
  data() {
    return {
      picList: [],
      showModal: false,
      showModalEdit: false,
      showModalApprove: false,
      form: {
        name: '',
        ws_product_id: '',
      },
      approvalForm: {
        pic_id: '',
        file_path: null,
      },
      approval: {
        pic_id: '',
        plan: '',
      },
      pricing: {
        utility: 0,
        authentication: 0,
        marketing: 0,
        service: 0,
      },
      accepted_ext: {
        document: '.pdf, .xls, .xlsx, .docx, .doc, .csv, .ppt, .pptx, .txt',
      },
      // selected: '',
      // options: [
      //   { text: 'Post Paid', value: 'postpaid' },
      //   { text: 'Pre Paid', value: 'prepaid' },
      // ],
      editedId: '',
      approved_by: '',
      product: [],
      need_approve_new_wappin: false,
      showModalTerms: false,
      is_accept_terms: false,
      loaderStack: 0,
      loader: null,
      loading: {
        approveSubmit: false,
      },
      company_id: this.$store.state.company.activeCompany.id,
      workspace_id: '',
      workspace_name: '',
    };
  },
  computed: {
    workspaces() {
      return this.$store.state.backOffice.workspaces;
    },
    checkUser() {
      return this.$store.state.backOffice.userProfile;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  mounted() {
    this.showLoader();
    this.$store.dispatch('backOffice/loadWorkspaces', this.company_id)
      .then(() => {
        this.hideLoader();
      }).catch((err) => {
        console.log(err);
        this.hideLoader();
      });
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    loadWorkspaces() {
      this.$store.dispatch('workspace/loadWorkspaces', {
        workspace_ids: this.userMemberWorkspaceIds,
      }).then(async (res) => {
        if (res.data.rows.length === 0) {
          this.showModal = true;
        }
        this.need_approve_new_wappin = false;
        // Load workspaces stats
        this.loadStats();
      }).catch((err) => {
        if (err === 'need_approve_new_wappin') {
          this.need_approve_new_wappin = true;
          this.showModalTerms = true;
        }
      });
    },
    loadStats() {
      this.workspaces.forEach((value, index) => {
        analyticApi.workspaceStats({ workspace_id: value._id, company_id: value.company_id })
          .then((result) => {
            this.workspaces[index].stats = result.data;
            this.workspaces[index].stats_loading = false;
          });
      });
    },
    async manage(workspace) {
      await this.$store.dispatch('backOffice/setActiveCompany', this.company_id);
      this.$store.dispatch('workspace/setActiveWorkspace', workspace)
        .then(() => {
          this.$router.push('/dashboard');
        });
    },
    async checkWorkspaceBalance(id) {
      const response = await workspacesAPI.check_balance({
        id,
      });
      await popupErrorMessages(response);
      this.$store.dispatch('workspace/setWorkspaceBalance', response.data.balance);
    },
    clearForm() {
      this.form.name = '';
      this.form.ws_product_id = '';
    },
    closeModal() {
      this.showModal = false;
      this.showModalEdit = false;
      this.clearForm();
    },
    async loadProduct() {
      await wsProductAPI.getList()
        .then((res) => {
          this.product = res.data.rows.map((item) => ({
            text: item.name,
            value: item._id,
          }));
        });
    },
    async loadPIC() {
      await usersAPI.getPIC()
        .then((res) => {
          this.picList = res.data.rows.map((item) => ({
            text: item.full_name,
            value: item.id,
          }));
        });
    },
    async createWorkspace() {
      const loader = this.$loading.show();
      await workspacesAPI.create(this.form)
        .then(async () => {
          // await this.$store.dispatch('ui/alertSuccess', 'Data berhasil ditambahkan');
          // this.showAlert();
          await this.$store.dispatch('workspace/loadWorkspaces', {
            workspace_ids: this.userMemberWorkspaceIds,
          });
          this.loadStats();
          this.closeModal();
        });
      loader.hide();
    },
    editWorkspace(workspace) {
      this.showLoader();
      this.editedId = workspace._id;
      this.form.name = workspace.name;
      this.approval.pic_id = workspace.pic_id;
      this.approval.plan = workspace.products[0]._id;
      this.approved_by = workspace.approved_by;
      if (workspace.products[0].is_postpaid !== undefined) {
        this.selected = workspace.products[0].is_postpaid === true ? 'postpaid' : 'prepaid';
      } else {
        this.selected = '';
      }
      if (workspace.products[0].pricing && workspace.products[0].pricing.whatsapp) {
        const priceList = workspace.products[0].pricing.whatsapp;
        this.pricing.authentication = priceList.authentication_conversation;
        this.pricing.utility = priceList.utility_conversation;
        this.pricing.marketing = priceList.marketing_conversation;
        this.pricing.service = priceList.service_conversation;
      } else {
        this.pricing.authentication = 0;
        this.pricing.utility = 0;
        this.pricing.marketing = 0;
        this.pricing.service = 0;
      }
      this.loadPIC();
      this.loadProduct();
      this.showModalEdit = true;
      this.hideLoader();
    },
    async saveEdit() {
      this.showLoader();
      await workspacesAPI.update(this.editedId, {
        name: this.form.name,
        pricing: {
          utility: this.pricing.utility,
          authentication: this.pricing.authentication,
          marketing: this.pricing.marketing,
          service: this.pricing.service,
        },
        approval: this.approval,
      }).then(async () => {
        this.$message({
          message: this.$t('workspace.success.edit'),
          type: 'success',
        });
        await this.$store.dispatch('backOffice/loadWorkspaces', this.company_id);
        this.loadStats();
        this.closeModal();
      });
      this.hideLoader();
    },
    handleShowQR(id) {
      const viewer = this.$refs[`viewer_${id}`].$viewer;
      viewer.show();
    },
    handlerApprovedBtn(workspace) {
      this.workspace_id = workspace._id;
      this.workspace_name = workspace.name;
      this.approvalForm.pic_id = workspace.pic_id;
      this.showModalApprove = true;
      this.loadPIC();
    },
    handlerHideModalApprove() {
      this.workspace_id = '';
      this.workspace_name = '';
      this.approvalForm = {
        pic_id: '',
        file_path: null,
      };
      this.showModalApprove = false;
    },
    async approveWorkspace() {
      this.loading.approveSubmit = true;
      const fileName = this.approvalForm.file_path.name.toLowerCase();
      const fileExt = `.${mime.getExtension(this.approvalForm.file_path.type)}`;
      const type = 'document';
      if (fileName.includes('quotation')) {
        const accepted = this.accepted_ext[type].split(',').map((v) => v.trim());
        if (!accepted.includes(fileExt)) {
          this.$message({
            message: this.$t('general.error.file_not_supported'),
            type: 'warning',
            duration: 10 * 1000,
          });
          return;
        }
        await workspacesAPI.approval(this.workspace_id, this.company_id, { pic_id: this.approvalForm.pic_id })
          .then(async (response) => {
            await popupErrorMessages(response);
            await this.createDocument();
            // this.$message({
            //   message: this.$t('workspace.success.approve'),
            //   type: 'success',
            // });
            await this.$store.dispatch('backOffice/loadWorkspaces', this.company_id);
            this.loadStats();
            this.handlerHideModalApprove();
          });
      } else {
        this.$message({
          message: this.$t('general.error.file_not_supported'),
          type: 'warning',
          duration: 10 * 1000,
        });
        return;
      }
      this.loading.approveSubmit = false;
    },
    async createDocument() {
      const documentForm = {
        file_name: `Quotation ${this.workspace_name}`,
        category: 'Quotation',
        company_id: this.company_id,
      };
      documentForm.file = await base64File(this.approvalForm.file_path);
      const response = await documentAPI.createDocument(
        documentForm,
      ).catch(() => {});
      await popupErrorMessages(response);
      this.$message({
        message: this.$t('workspace.success.approve'),
        type: 'success',
      });
    },
    postpaidWorkspace(workspace) {
      this.$confirm(this.$t('workspace.confirm.postpaid'), '', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await workspacesAPI.pricing_plan(workspace._id, this.company_id)
              .then(async (response) => {
                await popupErrorMessages(response);
                this.$message({
                  message: this.$t('workspace.success.postpaid'),
                  type: 'success',
                });
                await this.$store.dispatch('backOffice/loadWorkspaces', this.company_id);
                this.loadStats();
              });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
  },
  components: {
    Status: () => import('../components/Status.vue'),
    TermsCondition: TermsCondWappinNew,
    // Viewer,
  },
};
</script>
